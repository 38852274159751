/*Navigation js*/
jQuery(function($) {
    $(".nav-toggle").click(function() {
        if ($('body').hasClass("nav-closed")) {
            $('body').removeClass("nav-closed");
            $('body').addClass("nav-open");

            $('body').scrollTop(0);
        } else {
            $('body').removeClass("nav-open");
            $('body').addClass("nav-closed");
        }
    });
});
